@media only screen and (max-width: 395px) {
    .settings .settings-header-name {
        font-size: 32px !important;
    }
    .settings .settings-section .settings-section-name {
        font-size: 28px !important;
    }
}

.settings .settings-header-name {
    font-family: "Montserrat sBold", sans-serif;
    font-size: 48px;
    color: white;
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
}
.settings .settings-header-name a {
    text-decoration: none;
    width: 140px;
    height: 48px;
    color: white;
    background-color: #25272D;
    border-radius: 4px;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    font-family: "Proxima Nova Rg", sans-serif;
}
.settings .settings-header-name a svg {
    fill: white;
}
.settings .settings-section {
    border-bottom: 1px solid #2F3137;
    padding-bottom: 40px;
}
.settings .settings-section:last-child {
    border-bottom: none;
    padding-bottom: 0;
}
.settings .settings-section .settings-section-name {
    font-family: "Montserrat sBold", sans-serif;
    font-size: 36px;
    color: white;
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
}
.settings .settings-section .settings-section-tr {
    display: grid;
    grid-template-columns: repeat(auto-fit, 188px);
    row-gap: 32px;
    margin-top: 20px;
    column-gap: 24px;
    justify-content: start;
}
.settings .settings-section .settings-section-tr.no-margin {
    margin-top: 5px;
}
.settings .settings-section .settings-section-tr.long {
    grid-template-columns: auto;
    justify-content: unset;
}
.settings .settings-section .settings-section-tr.long .settings-section-td-data {
    justify-content: unset;
    background-color: #25272D;
    max-width: 324px;
    border-radius: 4px;
}
.settings .settings-section .settings-section-tr.long .settings-section-td-data label {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 40px auto;
}
.settings .settings-section .settings-section-tr.long .settings-section-td-data label span {
    background-color: #2F3137;
    border-radius: 4px;
    display: grid;
    align-items: center;
    justify-items: center;
}
.settings .settings-section .settings-section-tr .settings-section-td {
    display: grid;
    grid-auto-flow: row;
}
.settings .settings-section .settings-section-tr .settings-section-td-name {
    color: white;
    font-size: 24px;
    margin-bottom: 8px;
}
.settings .settings-section .settings-section-tr .settings-section-td-data {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    column-gap: 16px;
}
.settings .settings-section .settings-section-tr .settings-section-td-data .text-input {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 324px;
    height: 35px;
    background-color: #25272D;
    border: none;
    padding: 12px;
    font-size: 14px;
    color: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    font-family: inherit;
    border-radius: 4px;
}
.settings .settings-section .settings-section-tr .settings-section-td-data .text-input::placeholder {
    color: #61646B;
}
.settings .settings-section button {
    height: 48px;
    width: 140px;
    border-radius: 4px;
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    justify-self: end;
    font-family: "Proxima Nova Rg", sans-serif;
}
.settings .settings-section .settings-section-tr .settings-section-td-checkbox-input {
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    cursor: pointer;
    height: 40px;
}
.settings .settings-section .settings-section-tr .settings-section-td-checkbox-input .settings-section-td-checkbox-input-text {
    padding-left: 4px;
    color: inherit;
    display: inline-flex;
    align-items: center;
    user-select: none;
}
.settings .settings-section .settings-section-tr .settings-section-td-checkbox-input input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.settings .settings-section .settings-section-tr .settings-section-td-checkbox-input input:checked + .settings-section-td-checkbox-input-text {
    color: white;
}
.settings .settings-section .settings-section-tr .settings-section-td-checkbox-input .settings-section-td-checkbox-input-text::before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: #61646B;
    border-radius: 4px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center center;
}
.settings .settings-section .settings-section-tr .settings-section-td-checkbox-input input:checked+.settings-section-td-checkbox-input-text::before {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12.5L10.3103 18L19 6' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
}
.settings .settings-section .settings-section-tr .settings-section-td-data .setting-change-password {
    cursor: pointer;
    color: #2081DF;
}