.zoomed-modal-container {
    background-color: rgba(0, 0, 0, 0.712);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    }


    .zoomed-modal-content {
        display: flex;
        justify-content: center;
        max-width: 75%;
        position: relative;
        max-height: 80%;
        align-items: center;
    }

    .zoomed-modal-closeIcon {
        position: absolute;
        right: 55px;
        top: 20px;
        color: #b5b3b3;
        cursor: pointer;
    }

.zoom-modal-img {
    width: 100%;
    height: 100%;
}