@font-face {
    font-family: Proxima Nova Rg;
    src: url(../fonts/ProximaNova-Regular.ttf);
  }
  
  @font-face {
    font-family: Montserrat sBold;
    src: url(../fonts/Montserrat-SemiBold.ttf);
  }


.common-modal-container {
background-color: rgba(0, 0, 0, 0.815);
position: fixed;
width: 100%;
height: 100%;
left: 0;
right: 0;
top: 0;
z-index: 30;
display: flex;
justify-content: center;
align-items: center;
}



.common-modal-content {
background-color: #1c1d22;
max-width: 70%;
height: 70%;
position: relative;
border-radius: 25px;
}

.common-modal-closeIcon {
    margin-left: 93%;
    padding: 2% 0% 0 0;
    cursor: pointer;
}

.common-smallModal {
width: 328px;
min-height: 365px;
background-color: #1c1d22;
position: relative;
border-radius: 25px;
padding: 14px 20px;

}

.common-modal-closeIcon:hover {
    color: #f2f2f3;
    
}


.common-modal-info {
    padding: 0 0 0 3%;
    overflow-y: scroll; 
    scrollbar-width: 0;
    overflow-y: none;
    color: #f2f2f3;
}


.common-smallModal-info {
  padding: 0 0 0 3%;
  overflow-y: scroll; 
  scrollbar-width: 0;
  overflow-y:hidden;
  color: #f2f2f3;
}


.common-modal-info::-webkit-scrollbar {
    width: 6px;
    overflow-y: none;

  }

  .common-modal-info::-webkit-scrollbar-track {
      background-color: #2F3137;
   
  }

  .common-modal-info::-webkit-scrollbar-thumb {
    box-shadow: inset 5px 5px 90px #767c8948;
  }



  .common-modal-header {
    font-family: 'Montserrat sBold';
    font-size: 25px;
  }

  .common-modal-text {
      font-family: 'Proxima Nova Rg';
      font-size:18px;
  }


  .common-modal-gradient {
    position: absolute;
        width: 90%;
        
  }