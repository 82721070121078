@media only screen and (max-width: 470px) {
    .log table td {
        font-size: 12px !important;
    }
    .log table .arrow {
        display: none !important;
    }
}
.log .log-header-name {
    font-family: "Montserrat sBold", sans-serif;
    font-size: 48px;
    color: white;
    margin-bottom: 32px;
}
.log .log-buttons {
    display: grid;
    grid-gap: 15px;
    margin-top: 32px;
    grid-template-columns: repeat(auto-fit, 136px);
}
.log .log-buttons div {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    background-color: #2F3137;
    height: 64px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
}
.log .log-buttons div:hover {
    color: white;
}
.log .log-buttons div.active {
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    color: white;
}
.log .log-search {
    display: grid;
    grid-template-columns: repeat(auto-fit, 160px);
    row-gap: 32px;
    margin-top: 20px;
    column-gap: 24px;
    justify-content: start;
    margin-bottom: 32px;
}
.log .log-search .log-search-td {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 1fr min-content;
}
.log .log-search .log-search-td-name {
    color: white;
    font-size: 16px;
    margin-bottom: 8px;
}
.log .log-search .log-search-td-data {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    background-color: #25272D;
    border-radius: 4px;
    height: 48px;
}
.log .log-search .log-search-td-data span {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background-color: #2F3137;
    display: grid;
    align-items: center;
    justify-items: center;
}
.log .log-search .log-search-td-data .text-input {
    max-width: 112px;
    height: 48px;
    background-color: #25272D;
    border: none;
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    color: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    font-family: "Proxima Nova Rg", sans-serif;
}
.log .log-search .log-search-show {
    height: 48px;
    width: 140px;
    border-radius: 4px;
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    font-family: "Proxima Nova Rg", sans-serif;
}
.log table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}
.log table th {
    font-size: 14px;
    height: 40px;
}
.log table td {
    background-color: #25272D;
    height: 80px;
    color: white;
}
.log table tbody tr td:first-child {
    border-radius: 8px 0 0 8px;
}
.log table tbody tr td:last-child {
    border-radius: 0 8px 8px 0;
}
.log table td, th {
    text-align: center;
    vertical-align: middle !important;
}
.log .log-operation {
    text-align: center;
}
.log .log-description {
    word-break: break-all;
    max-width: 40%;
}
.log table td .log-roles {
    width: 172px;
    display: grid;
    grid-template-columns: 100px 36px 36px;
    border-radius: 4px;
    border: 1px solid #2F3137;
    align-self: center;
    margin: 12px auto;
}
.log table td .log-roles div {
    border: 1px solid #2F3137;
}
.log table td .log-roles div:nth-child(3n+1) {
    text-align: left;
}
.log table td .log-roles div:nth-child(3n+2) {
    color: #E13E3E;
}
.log table td .log-roles div:nth-child(3n) {
    color: green;
}
.log .log-table-center {
    display: grid;
    align-items: center;
    justify-items: center;
}
.log .log-table-date {
    color: #767C89;
}
.log table .log-table-spacer {
    height: 8px;
}