@font-face {
    font-family: Proxima Nova Rg;
    src: url(../../../fonts/ProximaNova-Regular.ttf);
}

@font-face {
    font-family: Montserrat sBold;
    src: url(../../../fonts/Montserrat-SemiBold.ttf);
}




.referral-header {
    font-weight: 600;
    font-size: 24px;
    line-height: 64px;
    font-family: "Montserrat sBold", sans-serif;
    color: #F2F2F3;
}

.referral-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Proxima Nova Rg';
    color: #F7F7F7;
}
.referrals {
    display: flex;
    align-items: center;
}
.referrals-icon {
    color: #777C88;
    margin-left: 10px;
    margin-bottom: 2px;
    cursor: pointer;
}
.referrals-icon:hover {
    color: #FFFFFF;
}
.referral-revenue {
    font-size: 16px;
    line-height: 19px;
    font-family: "Montserrat sBold", sans-serif;
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.referral-link {
    color: #2081DF;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Proxima Nova Rg';
}

.referral-breaking-line {
    width: 100%;
    height: 1px;
    background-color: #2F3137;
    margin: 25px 0;
    border: none;
}

.referral-modal {
    width: 800px;
}