@font-face {
    font-family: Proxima Nova Rg;
    src: url(../../../fonts/ProximaNova-Regular.ttf);
  }
  @font-face {
    font-family: Montserrat sBold;
    src: url(../../../fonts/Montserrat-SemiBold.ttf);
  }


.mail-container {
    display: flex;
    flex-direction: column;
}




.mail-search-container {
    margin: 10px 0;
}
.mail-selectedUsers-container {
    color: #f2f2f3;
    padding: 10px 0;
}
.mail-selectedUsers-content {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: 'Proxima Nova Rg';
}
.mail-userSearch {
    height: 30px;
    width: 100%;
    background-color: #25272C;
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    font-size: 16px;
    color: #61646B;
    border-radius: 4px;
    color: #f2f2f3;
    margin: 0;
    border: none;
    padding: 5px;
    border-radius: 8px;
    position: relative;
}
.mail-userSearch:focus {
    outline: none !important;
}

.mail-searchOption-container {
    padding: 10px 3px;
    cursor: pointer;
}
.mail-searchOption-container:hover {
    background-color: #151515b9;
    color: #f2f2f3;
}
.mail-search-dropDown {
    border-top: 1px solid #767c89;
    height: 20%;
    background-color: #25272C;
    overflow-y: scroll;
}



.mail-info-container {
margin: 10px 0;
}

.mail-subject {
height: 40px;
width: 100%;
background-color: #25272C;
font-family: 'Proxima Nova Rg';
font-style: normal;
font-size: 16px;
color: #61646B;
border-radius: 4px;
color: #f2f2f3;
margin: 0;
border: none;
border-bottom: 1px solid #767c89;
padding: 5px;
border-radius: 8px;
}

.mail-textarea {
    padding: 5px;
    padding-top: 13px;
    width: 100%;
    resize: none;
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    font-size: 16px;
    color: #f2f2f3;
    background-color: #25272C;
    border: none;
    border-radius: 8px;
}
.mail-textarea:focus, .mail-subject:focus { 
    outline: none !important;
    border-color: #719ECE;
    box-shadow: 0 0 10px #719ECE;
}

.mail-checkbox-container {
    display: flex;
}

.mail-checkbox-content {
    color: #f2f2f3;
    display:flex;
    margin:10px;
    font-size: 18px;
    font-family: 'Proxima Nova Rg';
}

.mail-checkbox {
    width: 16px;
    height: 16px;
    background-color:#767c89;
    background: #767c89;
    -webkit-filter:invert(0.5);
    margin-right:10px
}

.mail-checkbox:checked {
    color: white;
    background-color: linear-gradient(274.02deg, #228de2 0%, #4252e6 50.52%, #4428b6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-filter:none;
}

.mail-button--disabled {
    height: 48px;
    width: 140px;
    border-radius: 4px;
    background: #767c89;
    border: none;
    color: white;
    font-size: 18px;
    cursor: default;
    outline: none;
    justify-self: end;
    font-family: "Proxima Nova Rg", sans-serif;
}

.mail-button {
    height: 48px;
    width: 140px;
    border-radius: 4px;
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    outline: none;
    justify-self: end;
    font-family: "Proxima Nova Rg", sans-serif;
}