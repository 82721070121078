@import "../../variables";

.messages-container {
  margin: 20px 0;
  max-width: 100%;
}

.messages__message {
  margin: 10px 0;
  word-break: break-all;
  max-width: 45%;
  flex-direction: column;
}

.you {
  text-align: right;
  margin-left: 45%;
}

.other {
  text-align: left;
  margin-right: 45%;
}

.messages__balanceMessage {
  &_container {
    width: 100%;
    position: relative;
    background-color: #2F3137;
    overflow: clip;
    border-radius: 10px;
    &-mirrored {
      text-align: left;
    }
    &-qr {
      background-color: white;
      padding: 19px;
      border-radius: 10px;
      position: relative;
      z-index: 5;

      & img {
        width: 129px;
        height: 129px;
      }
    }

    &-text {
      position: relative;
      z-index: 5;

    }
  }

  &_content {
    padding: 16px;
    font-family: $font-proxima;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_textSection-header {
    font-size: 16px;
    line-height: 19px;
    font-family: "Montserrat sBold", sans-serif;
    background: linear-gradient(138.62deg, #459CFF 16.79%, #3011F8 73.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0;
    text-transform: uppercase;
  }
  &_textSection-text {
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0;
  }
  &_textSection-icon {
    margin-left: 15px;
    margin-right: 5px;
    cursor: pointer;
    flex-shrink: 0;
  }
  &_vector-1 {
    width: 50%;
    height: 50%;
    position: absolute;
    bottom: 0;
    left: -10%;
    z-index: 1;
  }

  &_vector-2 {
    z-index: 1;
    width: 50%;
    height: 50%;
    top: 0;
    right: 25%;
    position: absolute;
  }
}

.messages__message {
  &--name,
  &--text,
  &--date {
    display: grid;
    align-items: center;
    height: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  &--name {
    grid-area: name;
    padding: 0;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    font-family: $font-proxima;
    color: #babdc6;
  }

  &--text {
    align-items: center;
    justify-content: center;
    background-color: $color-gray2;
    grid-area: text;
    color: #f2f2f3;
    min-height: 56px;
    margin-top: 4px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-family: $font-proxima;
    border-radius: 8px;
    text-align: left;
    display: inline-flex;
    white-space: pre-wrap;
  }
}

.send-message {
  & .button-standard--disabled {
    opacity: 0.6;
  }

  &__button {
    margin-top: 10px;
    text-align: right;
  }

  textarea {
    height: unset;
    width: 100%;
    resize: none;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-family: $font-proxima;
    position: relative;
  }
}

.ticket-chat-header {
  color: #f2f2f3;
  font-weight: 600;
  font-size: 36px;
  line-height: 48px;
  font-family: $font-montserrat;
  margin-bottom: 8px;
}

.ticket-chat-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  font-family: $font-proxima;
  color: #babdc6;
}

.ticket-chat-header--breakLine {
  height: 1px;
  width: 100%;
  background: #2f3137;
  margin: 24px 0 36px 0;
}

.ticket-chat-action-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .ticket-chat-label {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-family: $font-proxima;
    color: #babdc6;
  }

  .checkbox-solved,
  .checkbox-unresolved {
    width: 30px;
    height: 30px;
    background-color: #767c89;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.checkbox-solved {
  margin: 0 8px;
}

.chat-checkbox-chosen {
  background: linear-gradient(274.02deg, #228de2 0%, #4252e6 50.52%, #4428b6 100%);
}

.ticket-chat-info {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  font-family: $font-proxima;
}

.ticket-chat-info:nth-child(odd) {
  padding: 0 16px;
}

.ticket-chat-info-title {
  color: #babdc6;
}

.tickets-attach-icon {
  position: absolute;
  right: 15px;
  padding: 10px;
  transform: rotate(30deg);
  cursor: pointer;
}

.tickets-attach-icon:hover {
  color: #f2f2f3;
}

///preview images


.img-preview-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.imgs-preview-container {
  position: relative;
  margin-bottom: 15px;
  margin-right: 16px;
}

.img-preview {
  width: 107px;
  height: 90px;
  border-radius: 5px;
}

.img-preview-close-icon {
  color: white;
  padding: 2px;
  background-color: #2F3137;
  position: absolute;
  right: 0;
  margin: 5px;
  cursor: pointer;
}


.ticketChat-img-container {
  width: 50%;
}

.ticket-chat-img {
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
  margin-top: 16px;
  border-radius: 6px;
}


.ticket-chat-img:hover {
  opacity: 0.4;
}

.img-loader-container {
  margin-top: 16px;
  max-width: 80%;
  max-height: 80%;
  background-color: rgba(0, 0, 0, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticketsChat-textarea-container {
  position: relative;
}

.ticketChat-loader {
  animation: rotation 1.5s infinite linear;
  color: #61646b;
}

.ticket-copied {
  height: 20px;
}

@media (max-width: 445px) {
  .ticket-chat-info:nth-child(odd) {
    padding: 15px 0px;
  }
  .ticket-chat-info--container {
    display: flex;
    flex-direction: column;
  }
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}