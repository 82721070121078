@import "../LandingGeneral.scss";


.wrapper {
  @extend %wrapper;
  position: fixed;
  background-color:#1c1d22ad;
  z-index: 100;
  transition: transform 500ms ease-in-out;
}
.header_hidden {
  transform: translateY(-120px);
  transition: transform 700ms ease-in-out;
}
.content {
  position: relative;
  z-index: 2;
  height:88px;
  display: flex;
  justify-content: space-between;
  @extend %content_desktop;
  align-items: center;
  nav {
  a {
    padding: 32px;
    text-decoration: none;
    color: $fcNav_unactive;
    font-family: $font-proxima;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  a:hover {
    color: $fc_main;
  }
}
}

.logo_container {
  img {
    width: 112px;
    height: 48px;
  }
}


.sign_up_container {
  display: flex;
  align-items: center;

  a {
    font-family: $font-proxima;
    color: $fcNav_unactive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    padding-left: 8px;
    text-decoration: none;
    cursor: pointer;
  }
}

///MEDIA///
@media (max-width: 992px) {
  .wrapper {
    position: static;
  }
  .content {
 nav {
    a {
      padding: 10px;
    }
  }
 
  }
}

@media (max-width: 768px) {
  .wrapper {
    position: static;
  }
  .content {
    padding: 0 15px;
  }
  .header_hidden {
    transform: translateY(0px);
  }
  nav {
    display: none;
  }
}
