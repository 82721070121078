@font-face {
    font-family: Proxima Nova Rg;
    src: url(../../fonts/ProximaNova-Regular.ttf);
  }

@media only screen and (max-width: 765px) {
    .accounts {
        position: relative;
    }
    .accounts .accounts-table .create-date, .multi {
        display: none !important;
    }
    .accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-info-tr {
        grid-template-columns: repeat(auto-fill, minmax(min-content, 100px)) !important;
        grid-auto-flow: unset !important;
        grid-gap: 10px !important;
    }
    .accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-icon {
        height: 100% !important;
    }
}

.accountTable__accountId--verified {
    margin-left: 18px;
}
@media only screen and (max-width: 660px) {
    .accounts {
        position: relative;
    }
    .accounts .accounts-table .accounts-table-bm-id {
        width: 1% !important;
    }
    .accounts .accounts-table .accounts-table-id {
        grid-template-columns: 4px min-content auto !important;
    }
}
@media only screen and (max-width: 545px) {
    .accounts {
        position: relative;
    }
    .accounts .accounts-header .accounts-header-name {
        font-size: 38px !important;
    }
    .accounts .accounts-table th:first-child {
        width: unset !important;
    }
    .accounts .accounts-table td,th {
        font-size: 12px !important;
    }
    .accounts .accounts-table .accounts-table-id {
        grid-template-columns: 4px auto !important;
    }
    .accounts .accounts-table .accounts-table-id .accounts-table-id-status {
        grid-row: 1 / span 2 !important;
    }
}

.accounts .accounts-header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    height: 64px;
    padding: 16px 0 29px 0;
}
.accounts .accounts-header .accounts-header-name {
    font-family: "Montserrat sBold", sans-serif;
    font-size: 48px;
    color: white;
    box-sizing: initial;
    margin-bottom: 32px;
}
.accounts .accounts-header a {
    text-decoration: none;
}
.accounts .accounts-header a div {
    width: 160px;
    height: 48px;
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    color: white;
    border-radius: 4px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: center;
    align-items: center;
    justify-items: center;
    padding: 14px;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.accounts .accounts-header a div path {
   stroke: white;
}
.accounts .accounts-admin-bar {
    display: grid;
    grid-auto-flow: column;
    padding-bottom: 20px;
    grid-auto-columns: 1fr auto;
}
.accounts .accounts-table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}
.accounts .accounts-table th:first-child{
    width: 205px;
}
.accounts .accounts-table .accounts-table-center {
    display: grid;
    align-items: center;
    justify-items: center;
}
.accounts .accounts-table .accounts-table-spacer {
    height: 8px;
}
.accounts .accounts-table tbody tr td:first-child {
    border-radius: 8px 0 0 8px;
}
.accounts .accounts-table tbody tr td:last-child {
    border-radius: 0 8px 8px 0;
}
.accounts .accounts-table tbody tr.opened td:first-child {
    border-radius: 8px 0 0 0;
}
.accounts .accounts-table tbody tr.opened td:last-child {
    border-radius: 0 8px 0 0;
}

.accounts .accounts-table tbody tr:nth-child(2) td:first-child {
    border-top: 1px solid #2F3137;
    border-radius: 0 0 8px 8px;
}
.accounts .accounts-table td {
    background-color: #25272D;
    height: 80px;
}
.accounts .accounts-table th {
    font-size: 14px;
    line-height: 16px;
    background-color: #1C1D22;
    height: 40px;
}
.accounts-table td, .accounts-table th {
    text-align: center;
    vertical-align: middle !important;
}
.accounts .accounts-table .accounts-table-id {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: 4px min-content auto;
    align-items: center;
    color: white;
}
.accounts .accounts-table .accounts-table-id .accounts-table-id-status {
    border-radius: 8px 0 0 8px;
    height: 100%;
}
.accounts .accounts-table .opened .accounts-table-id .accounts-table-id-status {
    border-radius: 8px 0 0 0;
    height: 100%;
}
.accounts .accounts-table .accounts-table-id .accounts-table-id-click {
    width: 32px;
    height: 32px;
    background-color: #2F3137;
    border-radius: 8px;
    display: grid;
    justify-items: center;
    align-items: center;
    margin: 0 8px 0 12px;
    cursor: pointer;
}
.accounts .accounts-table .accounts-table-id .accounts-table-id-click:hover path {
    stroke: white;
}
.accounts .accounts-table .accounts-table-user,.accounts-table-login, .accounts-table-date .accounts-table-date-time {
    color: white;
}
.accounts .accounts-table .accounts-table-limited {
    display: grid;
    align-content: center;
    justify-content: center;
    justify-items: center;
    font-size: 14px;
}
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-bar {
    width: 100px;
    height: 16px;
    background-color: #1C1D22;
    border-radius: 4px;
    margin-bottom: 4px;
    position: relative;
}
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-bar .accounts-table-limited-bar-label {
    position: absolute;
    color: white;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-bar .accounts-table-limited-bar-label:hover .label-mb {
    display: inline;
    cursor: default;
}
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-bar .accounts-table-limited-bar-label .label-mb {
    display: none;
}
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-bar .accounts-table-limited-bar-label:hover .label-percent {
    display: none;
}
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-bar .bar {
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    border-radius: 4px;
    height: 100%;
}
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-traffic {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 5px;
    position: relative;
}
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-icon {
    width: 32px;
    height: 32px;
    background-color: #2F3137;
    border-radius: 4px;
    display: grid;
    align-content: center;
    justify-content: center;
    cursor: pointer;
}

.accounts-table-bubble {
    position: absolute;
    background-color: #2F3137;
    width: 100px;
    height: 20px;
    color: #f2f2f3;
    top: -33px;
    font-family: 'Proxima Nova Rg';
    font-size: 16px;
    line-height: 16px;
    border-radius: 8px;
    display: none;
}

.accounts-table-limited-icon:hover .accounts-table-bubble {
   display: block;
}
.accounts-table-limited-icon:hover .accounts-table-bubble::before  {
    content: '';
    width: 0;
    height: 0;
    top: 15px;
    left: 45px;
    position: absolute;
    border-top: 9px solid transparent;
    border-right: 10px solid #2F3137;
    border-bottom: 9px solid transparent;
    transform: rotate(-90deg)
}

.refresh-bubble {
    left: 0px;
}

.plus-bubble {
    right: 3px;
}

.addTraffic__dropDown {
    width: 100%;
}          
.addTraffic__modal_text {
    margin-bottom: 40px;
}                    
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-traffic .accounts-table-limited-icon:hover:first-child:hover path{
    stroke: white;
}

.account-plus-icon:hover path {
    stroke: white;
}
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-icon:not(.no-cursor):hover path {
    fill: white;
}
.accounts .accounts-table .accounts-table-limited .accounts-table-limited-icon.no-cursor {
    cursor: initial;
}
.accounts .accounts-table .accounts-table-bm {
    height: 100%;
}
.accounts .accounts-table .accounts-table-bm .accounts-table-bm-label {
    height: 4px;
    width: 100%;
}
.accounts .accounts-table .accounts-table-bm .accounts-table-bm-icon {
    height: 100%;
    margin-top: -4px;
    display: grid;
    align-items: center;
    justify-items: center;
}
.accounts .accounts-table .accounts-table-bm .accounts-table-bm-icon .icon-green path {
    stroke: #37BA5B;
}
.accounts .accounts-table .accounts-table-bm .accounts-table-bm-icon .accounts-table-bm-icon-error path {
    fill: #E13E3E;
}
.accounts .accounts-table .accounts-table-multi {
    width: 100%;
    display: grid;
    justify-content: center;
    color: #37BA5B;
}
.accounts .accounts-table .accounts-table-multi .accounts-table-multi-icon {
    width: 32px;
    height: 32px;
    background-color: #2F3137;
    border-radius: 8px;
    display: grid;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}
.accounts .accounts-table .accounts-table-multi .accounts-table-multi-icon .accounts-table-multi-success path {
    stroke: #37BA5B;
}
.accounts .accounts-table .accounts-table-edit {
    width: 100%;
    display: grid;
    justify-content: center;
}
.accounts .accounts-table .accounts-table-edit div {
    width: 32px;
    height: 32px;
    background-color: #2F3137;
    border-radius: 8px;
    border: none;
    display: grid;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}
.accounts .accounts-table .accounts-table-edit div:hover path {
    fill: white;
}
/* Открывающаяся инфа */
.accounts .accounts-table .accounts-table-info-td {
    padding: 16px 16px 0 16px;
}
.accounts .accounts-table .accounts-table-info-tr {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 100px auto;
    padding-bottom: 16px;
}
.accounts .accounts-table .accounts-table-info-tr:nth-child(2) {
    padding-top: 16px;
    border-top: 1px solid #2F3137;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-icon {
    background-color: #2F3137;
    height: 129px;
    width: 88px;
    border-radius: 8px;
    display: grid;
    align-content: center;
    justify-items: center;
    justify-content: center;
    color: #E13E3E;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-icon div {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    border: none;
    margin-top: 20px;
    background-color: #25272D;
    cursor: pointer;
    display: grid;
    align-items: center;
    justify-items: center;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-icon div:hover path {
    fill: white;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-icon .red path {
    fill: #E13E3E;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-icon .red {
    margin-bottom: 20px;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-info-tr {
    display: grid;
    grid-auto-flow: column;
    height: 50%;
    justify-content: start;
    grid-gap: 20px;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-info-tr:nth-child(2) {
    padding-top: 16px;
    border-top: 1px solid #2F3137;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-info-tr div {
    text-align: start;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-info-tr div > div {
    color: white;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-info-tr .accounts-table-info-tr-info-icon {
    width: 47px;
    height: 47px;
    background-color: #2F3137;
    border-radius: 8px;
    display: grid;
    align-items: center;
    justify-items: center;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-info-tr .accounts-table-info-tr-info-copy {
    display: flex;
    flex-direction: column;
    border: none;
    height: 40px;
    width: 100%;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-info-tr .accounts-table-info-tr-info-copy div {
    padding: 0 8px 0 8px;
}

.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-info-tr .accounts-table-info-tr-info-copy .copy {
    cursor: pointer;
}
.accounts .accounts-table .accounts-table-info-td .accounts-table-info-tr .accounts-table-info-tr-info-tr .accounts-table-info-tr-info-copy .copy:hover path {
    fill: white;
}
.accounts .accounts-table .accounts-table-bm-id {
    width: 60px;
}
.accounts .accounts-table .accounts-table-bm-id select {
    width: 60px;
    height: 14px;
    color: white;
    background-color: #2F3137;
    border-radius: 4px;
    border: none;
    text-align-last: center;
    margin-bottom: 4px;
}
.accounts .accounts-table .accounts-table-bm-id .accounts-table-edit div {
    border-radius: 4px;
}
.accounts .accounts-table .accounts-table-bm-id .accounts-table-edit div:hover path {
    stroke: white;
}
.modal-window.modal-window--create-ticket {
    width: 100%;
    height: 100%;
    
}

.modal-window-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.fa-text {
    color: white;
   font-family:"Proxima Nova Rg", sans-serif;
}
.copy-item {
    cursor: pointer;
}

.accounts-copied {
    height: 20px;
}

// .accounts {
//     position: relative;
// }