@import "../LandingGeneral.scss";

.wrapper {
    @extend %wrapper;
    background-color: #25272d;
    margin: 0 auto;
    background-image: url("../assets/svgs/footerIcon.png");
    background-repeat: no-repeat;
    background-position-x: 101%;
    background-position-y: 55px;
    box-sizing: border-box;
}

.content {
    @extend %content_desktop;
    padding: 20px 16px;
    .grid_wrapper { 
        width: 100%;
        display: flex;
        // grid-template-areas:
        //   "socialMedia newsletter logo"
        //   "policies newsletter logo";
        // grid-template-columns: 1fr 1fr 1fr;
        justify-content: space-between;
        h2 {
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
            color: $fc_main;
            font-family: $font-proxima;

        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: $fcNav_unactive;
            font-family: $font-proxima;
        }

        ul {
            a {
                color: $fc_box-numbers;
                text-decoration: none;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                font-family: $font-proxima;
                padding-right: 24px;
                cursor: pointer;

            }
            a:hover {
                color: $fc_main;
            }
        }
    }
}

.socialMedia {
    // max-width: 496px;
    // min-height: 96px;
    flex-wrap: nowrap;
    white-space: nowrap;
    grid-area: socialMedia;
    p {
        padding: 8px 0 16px 0;
    }
}

.policies {
    // max-width: 444px;
    // min-height: 72px;
    margin-top: 15px;
    flex-wrap: nowrap;
    white-space: nowrap;
    grid-area: policies;
    ul {
        margin-top: 16px;
    }
}

.newsletter {
    display: flex;
    flex-direction: column;
    // max-width: 332px;
    grid-area: newsletter;
    .newsletter_h2 {
        margin-bottom: 8px;
        padding: 0;
    }
    .newsletter_p {
        margin-top: 1px;
    }
    .inputsBtn_container {
        display: flex;
        padding-right: 0px;
        flex-direction: column;
        .inputs {
            display: flex;
            padding-bottom: 30px;
            .input:nth-child(1) {
            margin-right: 18px;
            }
            @-moz-document url-prefix() {
                .input:nth-child(1) {
                    margin-right: 40px;
            }}
            input {
                height: 40px;
                margin-top: 16px;
                background-color: #2f3137;
                border-radius: 4px;
                border: none;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                font-family: $font-proxima;
                color: $fc_main;
                padding-left: 10px;
            }
            input::placeholder {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                font-family: $font-proxima;
                color: $fcNav_unactive;
                padding-left: 8px;
            }
        }
        .button {
            width: 90%;
            height: 48px;
            @extend %buttons_general_styles;
            font-style: normal;
            font-weight: 200;
            font-size: 16px;
            line-height: 24px;
            font-family: $font-proxima;
            color: $fc_main;
            letter-spacing: 1px;
            border: none;
            letter-spacing: 1px;
        }
        p {
            margin-top: 8px;
        }
    }
}
.logo {
    width: 112px;
    min-height: 202px;
    position: relative;
    grid-area: logo;
    img {
        position: absolute;
        bottom: 15px;
    }
}



@media (max-width: 1100px) {
    .newsletter {
        margin: 0;
        padding-left: 16px;
    }
    .logo {
        display: none;
    }
}

.socialMedia_header_mob {
    display: none;
}

@media (max-width: 992px) {
    .policies,
    .socialMedia {
        margin-left: 16px;
    }
    .newsletter {
        margin: 0 10px;


    }
}

@media (max-width: 768px) {
    .wrapper {
        background-position-y: bottom;
        background-image: url("../assets/svgs/footerIcon.svg");
        background-color: #25272d;
        background-repeat: no-repeat;
    }
    .content {
        max-width: none;
        padding: 24px 16px;
        margin: 0 auto;
        .grid_wrapper {
            display: grid;
            // max-height: 626px;
            align-items: flex-start;
            grid-template-columns: 1fr 2fr 1fr;
            grid-template-areas:
                "socialMedia policies"
                "newsletter newsletter"
                "logo none";
        }

    
    }
    .first_column {
        display: flex;
    }
    .socialMedia_header_mob {
        display: block;
    }
    .socialMedia_header_desk {
        display: none;
    }
    .socialMedia {
        padding-right: 16px;
        margin: 0;
        max-width: 136px;
        white-space: initial;
        p {
            display: none;
        }
        ul {
            display: flex;
            flex-direction: column;

            a {
                margin: 8px 0;
            }
        }
    }
    .policies {
        margin: 0;
        padding: 0;
        margin-top: 0;
        max-width: 136px;
        min-height: none;
        ul {
            margin: 0;
            display: flex;
            flex-direction: column;
            a {
                margin: 8px 0;
            }
        }
    }
    .newsletter {
        padding: 0;
        margin: 0;
        width: 100%;
        margin-top: 24px;
        .inputsBtn_container {
        .inputs {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            .input:nth-child(1) {
            margin-right: 0px;
            }
        input {
                width: 90%;
            }
        }
            .button {
                width: 93%;
            }
        }
    }
    .logo {
        display: block;
        width: 112px;
        min-height: 48px;
        position: static;
        padding-left: 0;
        grid-area: logo;
        margin: 0;
        margin-top: 48px;
        img {
            position: static;
        }
    }
 
}
