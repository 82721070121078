@font-face {
    font-family: Proxima Nova Rg;
    src: url(../fonts/ProximaNova-Regular.ttf);
  }
  
  
.attach-modal-container {
    background-color: rgba(0, 0, 0, 0.466);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    }
    
    .leave-drag-area {
        width: 86%;
        height: 100vh;
        position: absolute;
        right:1%;
        z-index: 100;
    }
    
    .attach-modal-content {
    background-color: #1c1d22;
    width: 284px;
    height: 300px;
    position: relative;
    border-radius: 25px;
    z-index: 2;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 1px dashed #767C89;
box-sizing: border-box;
border-radius: 16px;
    }

    .attach-modal-gradient {
        position: absolute;
            width: 25%;
            z-index: 1;
      }


      .attach-modal-text {
        font-family: 'Proxima Nova Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 22px;
      }
    
    .attach-modal-info {

        color: #f2f2f3;
    }


    
    
    
   
    