.skip {
  margin: 20px 0;

  .input-text {
    width: 100px;
    margin-right: 20px;
  }

  .button-standard {
    height: 40px;
  }
}