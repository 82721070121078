@font-face {
    font-family: Proxima Nova Rg;
    src: url(../fonts/ProximaNova-Regular.ttf);
  }
  @font-face {
    font-family: Montserrat sBold;
    src: url(../fonts/Montserrat-SemiBold.ttf);
  }



  .select-container {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    position: relative;
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #61646B;
  }

.select-input {
    height: 35px;
    background-color: #25272C;
    border: none;
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #61646B;
    padding-left:8px;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.select-placeholder {
  font-family: 'Proxima Nova Rg';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
    opacity: 1;
    color: #E5E5E5;;
}



.select-arrow {
    cursor: pointer;
    margin-right:5px;
    }
    
    .selectArrow-up {
    transform: rotate(180deg);
    }
        .select-dropDown-container {
        background: #25272C;
        border:none;
        position: absolute;
        z-index: 5;
        width: 100%;
        max-height: 120px;
        top: 35px;
        overflow-y: scroll; 
       scrollbar-width: 0;
       overflow-y: none;
       align-items: center;
    }
    .select-options-container {
        padding: 12px;
        margin: 0px 18px;
        cursor: pointer;
        color:#F2F2F3 ;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
    }
    
    .select-options-container:nth-child(1) {
        border-top: 1px solid #2F3137;
    }

    .select-dropDown-container::-webkit-scrollbar {
        width: 6px;
        overflow-y: none;
      }
    
      .select-dropDown-container::-webkit-scrollbar-track {
          background-color: #2F3137;
       
      }
    
      .select-dropDown-container::-webkit-scrollbar-thumb {
        box-shadow: inset 5px 5px 90px #767c8948;
      }
    
      .select-radio {
        appearance: none;
        background-color: #2F3137;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1.30em;
        height: 1.30em;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
    }
    .selectRadio__container {
      position: relative;
    }
    .radio-circle {
        width: 10px;
        height: 10px;
        border-radius: 40px;
        background: #61646B;
        z-index: 5;
    }
    
    .radio-circle--checked {
        width: 9px;
        height: 9px;
        border-radius: 30px;
        background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
        top:19%;
        right: 26%;
        position: absolute;
        z-index: 5;
    }

    .select-options-container:hover {
      color: #61646B;
      
    }