@font-face {
    font-family: Proxima Nova Rg;
    src: url(../../../fonts/ProximaNova-Regular.ttf);
}

@font-face {
    font-family: Montserrat sBold;
    src: url(../../../fonts/Montserrat-SemiBold.ttf);
}

.top-up-container {
    margin-right: 10px
}

.top-up-header-container {
    display: flex;
    align-items: center;
    align-items: center;
}

.top-up--header {
    font-weight: 600;
    font-size: 24px;
    line-height: 64px;
    font-family: "Montserrat sBold", sans-serif;
    color: #F2F2F3;
}

.top-up--balance {
    font-weight: 600;
    font-size: 24px;
    line-height: 64px;
    font-family: "Montserrat sBold", sans-serif;
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.top-up-form-container {
    display: flex;
    flex-direction: column;
    max-width: 320px;
    position: relative;
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #61646B;
}

.top-up--select-coin,
.top-up--amount {
    height: 35px;
    background-color: #25272C;
    border: none;
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: white;
    padding-left: 18px;
    border-radius: 4px;

}


.top-up--amount {
    margin: 20px 0 18px 0;
}

.top-up--amount::placeholder {
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #61646B;
    opacity: 1;
}

.top-up--button {
    height: 48px;
    color: #F2F2F3;
    border: none;
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    margin-bottom: 23px;
    cursor: pointer;
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
}


.top-up--breaking-line {
    width: 100%;
    height: 1px;
    background-color: #2F3137;
    margin: 25px 0;
    border: none;
}

.top-up--button-disabled {
    background: #767c89;
    cursor: initial;
}

.top-up-select--error {
    font-size: 18px;
    line-height: 24px;
    font-weight: normal;
    font-family: "Montserrat sBold", sans-serif;
    color: darkred;
}

.top-up--select-coin {
    font-family: 'Proxima Nova Rg';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;
}

.top-up-form-placeholder {
    font-family: 'Proxima Nova Rg';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #61646B;
    cursor: default;
    display: flex;
    align-items: center;
}

.top-up-select--arrow {
    margin-top: 5px;
    cursor: pointer;
    padding: 8px 18px 5px 8px;
}

.top-up-select--arrow_up {
    transform: rotate(180deg);
}

.top-up-drop-down--option-container {
    padding-top: 12px;
    margin: 0px 18px;
    cursor: pointer;
    color: #F2F2F3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
}

.top-up-drop-down--option-container:nth-child(1) {
    border-top: 1px solid #2F3137;
}

.top-up--select-drop-down-container {
    background: #25272C;
    border: none;
    position: absolute;
    z-index: 5;
    width: 100%;
    top: 35px;
    align-items: center;
    max-height: 70%;
    overflow-x: scroll;
}

.top-up-drop-down--content {
    display: flex;
    flex-direction: column;

}

.top-up-drop-down--content:hover {
    background-color: #2F3137;
}


.top-up-currency-name {
    color: #F2F2F3;
    font-family: 'Proxima Nova Rg';
}

.top-up-currency-img {
    margin-right: 11px;
    width: 19px;
    height: 19px;
}
.top-up-currency-img--chosen {
    width: 19px;
    height: 19px;
}
.top-up-img--container {
    display: flex;
    align-items: center;
}

.top-up--select-drop-down-container::-webkit-scrollbar {
    width: 6px;
    max-height: 70%;
}

.top-up--select-drop-down-container::-webkit-scrollbar-track {
    background-color: #2F3137;
}

.top-up--select-drop-down-container::-webkit-scrollbar-thumb {
    box-shadow: inset 5px 5px 90px #767c8948;
}

.top-up--radio {
    appearance: none;
    background-color: #2F3137;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
}

.top-up--radio-circle {
    width: 8px;
    height: 8px;
    border-radius: 40px;
    background: #61646B;
    margin-top: -16.5px;
    margin-left: 5.21px;
    position: absolute;
    z-index: 5;
}

.top-up--radio-circle--checked {
    width: 8px;
    height: 8px;
    border-radius: 40px;
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    margin-top: -16.5px;
    margin-left: 5.21px;
    position: absolute;
    z-index: 5;
}



.top-up-drop-down--option-container:hover {
    color: #61646B;
}