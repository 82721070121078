
.addReq-container {
    max-width: 50%;
}
.addReq-form {
    display: flex;
    flex-direction: column;
}



.add-Req-button {
    height: 48px;
    width: 140px;
    border-radius: 4px;
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    outline: none;
    justify-self: end;
}

.addReq-label {
    color: white;
font-size: 24px;
margin-bottom: 8px;
}


.addReq-field {
    height: 40px;
    width: 400px;
    background-color: #25272D;
    border: none;
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    color: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    margin: 16px 0;
}

.addReq-field::placeholder {
    color: #61646B;
}