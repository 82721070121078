@import "variables";
@import-normalize;

@font-face {
  font-family: Proxima Nova Rg;
  src: url(fonts/ProximaNova-Regular.ttf);
}

@font-face {
  font-family: Montserrat sBold;
  src: url(fonts/Montserrat-SemiBold.ttf);
}

html {
  scroll-behavior: smooth;

}
body {
  margin: 0;
  background: #1C1D22;
  color: #767C89;
  font-family: $font-proxima;
  overflow-x: hidden;
  width: 100%;
  scroll-behavior: smooth;

  &.mobile-no-refresh {
    overscroll-behavior-y: contain;
  }
}
.success {
  background-color: #37BA5B;
}
.danger {
  background-color: #E13E3E;
}
.primary {
  background-color: #2883E3;
}
.react-datepicker {
  background-color: #1C1D22 !important;
  .react-datepicker__header {
    background-color: #2F3137 !important;
  }
}

.react-datepicker .react-datepicker__header .react-datepicker__current-month,.react-datepicker__day-name,.react-datepicker__day {
  color: #ccc !important;
}
.react-datepicker .react-datepicker__day.react-datepicker__day--disabled {
  color: #767C89 !important;
}
.react-datepicker .react-datepicker__day:hover {
  background-color: #216ba5;
}
.react-datepicker .react-datepicker__day--today {
  color: white !important;
  font-size: 1.2em;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  display: grid;
  align-content: center;
  justify-content: center;
  &-window {
    background-color: #1C1D22;
    width: 300px;
    height: 400px;
    border-radius: 16px;
    padding: 48px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: 1fr max-content;
    justify-items: center;
    align-items: center;
    position: relative;
    color: white;
    button, .modal-button-link {
      width: 100%;
      height: 48px;
      border-radius: 4px;
      background: #2F3137;
      border: none;
      color: white;
      font-size: 16px;
      cursor: pointer;
      outline: none;
    }
    .modal-button-link {
      text-decoration: none;
      display: grid;
      align-items: center;
      justify-items: center;
    }
    .modal-window-close {
      position: absolute;
      right: 24px;
      top: 24px;
      cursor: pointer;
    }
    .modal-window-yes-no {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 40% 40%;
      width: 100%;
      column-gap: 20%;
    }
    .modal-window-data {
      width: 100%;
    }
  }
}
.not-found {
  display: grid;
  height: 100vh;
  grid-auto-flow: row;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.button-standard {
  height: 48px;
  width: 140px;
  border-radius: 4px;
  background: $color-gradient;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  outline: none;
}
.button-link {
  width: 160px;
  height: 48px;
  font-family: $font-proxima;
  background: $color-gradient;
  font-size: 16px;
  text-decoration: none;
  color: white;
  border-radius: 4px;
  display: grid;
  align-content: center;
  align-items: center;
  justify-items: center;
  padding: 14px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &-icon {
    grid-template-columns: min-content 1fr;
  }
}
.header-standard {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  font-family: $font-montserrat;
  font-size: 48px;
  color: white;
  margin-bottom: 32px;
}
.row-standard {
  display: grid;
  grid-auto-flow: row;
  margin-bottom: 32px;
  &__name {
    color: white;
    font-size: 24px;
    margin-bottom: 8px;
  }
  &__data {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    column-gap: 16px;
  }
}
.input-text {
  height: 40px;
  background-color: $color-gray1;
  border: none;
  border-radius: 4px;
  padding: 12px;
  font-size: 14px;
  color: white;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
}
.input-checkbox {
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  cursor: pointer;
  height: 40px;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:checked+.input-checkbox__label::before {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12.5L10.3103 18L19 6' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
    }
    &:checked + .input-checkbox__label {
      color: white;
    }
  }
  &__label {
    padding-left: 4px;
    color: inherit;
    display: inline-flex;
    align-items: center;
    user-select: none;
    &::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: #61646B;
      border-radius: 4px;
      margin-right: 8px;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
textarea {
  font-family: $font-proxima;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}