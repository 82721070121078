@import "../LandingGeneral.scss";


.wrapper {
    @extend %wrapper;
}
.content {
    @extend %content_desktop;
    margin-top: 168px;
    padding-bottom: 144px;
    display: flex;
    flex-direction: column;
    h1 {
        @extend %headers_exceptHome;
        margin-bottom: 46px;
    }
}

.text_container {
    max-width: 448px;
    max-height: 216px;
    h2 {
        font-family:$font-proxima ;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        color: $fc_main;
        margin-bottom: 24px;
    }
    p {
        font-family: $font-proxima;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $fc_minor;
    }
    p:last-child {
        margin-top: 16px;
    }
}

.info_container {
    display: flex;
    align-items: center;
}

.vector {
    position: relative;
    height: 100%;
    margin-right: 116px;
    z-index: 20;
    .gradient {
        position: absolute;
        top: 0%;
        z-index: -1;
    }
}

@media (max-width: 992px) {
    
 
    .vector {
        height: none;
        margin-right: 50px;
        z-index: 20;
}
}

@media (max-width: 768px) {
    .wrapper {
        background: url('../assets/svgs/fadeRobotMob.png');
        background-repeat: no-repeat;
        background-position-y:bottom 100%;
    }
    .content {
        margin: 56px 16px; 
        padding: 0;
        text-align: center;
       
        h1 {
            margin-bottom: 32px;
        }
    }
    .info_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .text_container {
        max-height: 336px;
        margin: 0;
        p:last-child {
            margin-top: 8px;
        }
    }
    .vector {
    margin: 0;
    margin-bottom: 24px;
}

}
