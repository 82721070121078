.archive .archive-header-name {
    font-family: "Montserrat sBold", sans-serif;
    font-size: 48px;
    color: white;
    padding-bottom: 24px;
}
.archive .archive-buttons {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    column-gap: 24px;
    padding-bottom: 24px;
    font-size: 24px;
}
.archive .archive-buttons a {
    display: grid;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 64px;
    border-radius: 8px;
    color: #767C89;
    outline: none;
    background-color: #2F3137;
    border: none;
    cursor: pointer;
    text-decoration: none;
}
.archive .archive-buttons a.active-button {
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    color: white;
}