.faq .faq-header-name {
    font-family: "Montserrat sBold", sans-serif;
    font-size: 48px;
    color: white;
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-auto-flow: column;
    align-content: center;
}
.faq .faq-header-name a div path {
    stroke: white;
}
.faq .faq-header-name a {
    text-decoration: none;
}
.faq .faq-header-name a div {
    font-family: "Proxima Nova Rg", sans-serif;
    height: 48px;
    width: 160px;
    border-radius: 4px;
    background: linear-gradient(274.02deg, #228DE2 0%, #4252E6 50.52%, #4428B6 100%);
    padding: 14px;
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: center;
    align-items: center;
    justify-items: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: white;
    font-size: 16px;
    cursor: pointer;
}
.faq .faq-tr {
    border-radius: 16px;
    background-color: #25272D;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 16px;
    margin-bottom: 16px;
}
.faq .faq-tr .faq-tr-name {
    color: white;
    font-size: 24px;
    margin-bottom: 8px;
    /*background-color: #2F3137;*/
    position: relative;
}
.faq .faq-tr .faq-tr-text {
}
.faq .faq-tr .faq-tr-name a {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 32px;
    height: 32px;
    background-color: #2F3137;
    border-radius: 8px;
    border: none;
    display: grid;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}
.faq .faq-tr .faq-tr-name a:hover path {
    fill: white;
}
