@font-face {
    font-family: Proxima Nova Rg;
    src: url(../../../fonts/ProximaNova-Regular.ttf);
}

.priceList-coin-container {
    max-width: 680px;
    min-height: 184px;
    background: #25272D;
    margin-top: 20px;
    padding: 1px 0;
    margin-right: 20px;
    border-radius: 8px;
}

.priceList-coin-content {
    margin: 16px;
}

.priceList-currency_type {
    margin: 0;
    margin-top: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Proxima Nova Rg';
}


.priceList-currency-name--container {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.priceList-currency-logo {
    width: 35px;
    height: 35px;
    margin-left: 5px;
    border-radius: 8px;
}

.priceList-delete--container, .priceList-requisites {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Proxima Nova Rg';
}

.priceList-wallet-label {
    font-weight: 400;
    font-size: 19px;
    line-height: 24px;
    font-family: 'Proxima Nova Rg';
}


.priceList-delete--container {
    margin-top: 24px;
}

.priceList-currency-name {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    font-family: 'Proxima Nova Rg';
}

.priceList-requisites {
    margin-top: 16px;
    font-family: 'Proxima Nova Rg';
    word-break: break-all;
}

.priceList-add-currency {
    max-width: 680px;
    min-height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2F3137;
    margin-top: 20px;
    border-radius: 8px;
}

.priceList-add-currency:hover path {
    fill: white;
}

.requisites-header {
    margin: 16px 0;
}

.priceList-icon {
    cursor: pointer;
    color: #767C89;
    background-color: #2F3137;
    border-radius: 5px;
    margin-right: 5px;
}

.priceList-add-requisites-icon:hover {
    color: #f2f2f3;
}


.priceList-req-field {

    height: 30px;
    width: 400px;
    background-color: #2F3137;
    border: none;
    border-radius: 4px;
    padding: 12px;
    font-size: 14px;
    color: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    padding: 16px;

}

.priceList-requisites-action-icons {
    display: flex;
    align-items: center;
}

.priceList-req-btn {
    background-color: #2F3137;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    border-radius: 8px;
    margin-right: 8px;
    cursor: pointer;
}

.priceList-req-btn-disabled {

}


